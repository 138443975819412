export class CONSTANT {
    // NOTE: keep variable same as value
    static jobPosts: string = 'jobPosts';
    static applicants: string = 'applicants';
    static members: string = 'members';
    static subscriptionUntil = 'subscriptionUntil';

    // NOTE: keep variable same as value in CAPS
    static SHARE = 'SHARE';
    static DOWNLOAD = 'DOWNLOAD';
    static PREVIEW = 'PREVIEW';
    static SETTING = 'SETTING';
    static RESUME_RENAME = 'RESUME_RENAME';
    static REVIEW_BY_INDUSTRY_EXPERT = 'REVIEW_BY_INDUSTRY_EXPERT';
    static SHAREABLE_LINK = 'SHAREABLE_LINK';
    static EDIT = 'EDIT';
    static STYLE_SETTINGS = 'STYLE_SETTINGS';
    static RESUME_CREATE = 'RESUME_CREATE';
    static RESUME_UPLOAD = 'RESUME_UPLOAD';
    static RESUME_DELETE = 'RESUME_DELETE';
    static RESUME_SELECTION = 'RESUME_SELECTION';
    static MARK_ALL_AS_READ = 'MARK_ALL_AS_READ';
    static CLEAR_ALL = 'CLEAR_ALL';
    static OPEN_TO_WORK = 'OPEN_TO_WORK';
    static EDIT_JOB_PROFILE = 'EDIT_JOB_PROFILE';
}
