const PARENT_ROUTE = '..';
const WILDCARD = '**';
const SLASH = '/';
const COLON = ':';
//auth-layout
const LOGIN = 'login';
const SIGNUP = 'signup';
const SIGNUP_UPLOAD = 'signup-upload';
const SIGNUP_PASSWORD = 'signup-password';
const REGISTER = 'register';
const FORGOT_PASSWORD = 'forgot-password';
const PASSWORD = 'password';
const CONFIRM_PASSWORD = 'confirmPassword';
const FORGOT_PASSWORD_SUCCESS = 'forgot-password-success';
const RESET_PASSWORD = 'reset-password';
const RESET_PASSWORD_SUCCESS = 'reset-password-success';
const INFORMATION = 'information';
const CREDENTIALS = 'credentials';
const SETUP_PROFILE = 'setup-profile';
const SUBDOMAIN = 'SUBDOMAIN';
const PORTFOLIO_STATE = 'portfolioState';
const BACK_URL = 'backUrl';
const MOBILE_TOKEN = 'mobileToken';
const NAVIGATE_BACK_URL = 'navigateBackUrl';

//authentication
const AUTH = 'auth';
const AUTHORIZATION = 'Authorization';
const JOIN_WAITLIST = 'join-waitlist';
const UNIVERSITY_WAITLIST = 'university-waitlist';
const EMPLOYER_WAITLIST = 'employer-waitlist';
const SUCCESS = 'success';
const FULL_NAME = 'fullName';
const EMAIL = 'email';
const WAITLIST = 'waitlist';
const WAITLIST_ID = 'waitlistId';
const EMPLOYER_ACCOUNT_ACTIVATION = 'employer-account-activation';
const CONTACT_PERSON = 'contact-person';
const CREATE = 'create';
const JOB_PROFILE_ID = 'jobProfileId';
const USER_TYPE = 'user-type';
const GET_STARTED = 'get-started';
const VERIFY_EMAIL = 'verify-email';
const FORM_SUCCESS = 'form-success';
const QUESTIONNAIRE = 'questionnaire';

//candidate
const C = 'c';
const R = 'r';
const INVITE = 'invite';
const SAVE = 'save';
const CANDIDATES = 'candidates';
const ACTIVE_CANDIDATES = 'active-candidates';
const INVITED_CANDIDATES = 'invited-candidates';
const HIRE_RESUME_EXPERT = 'hire-resume-expert';
const RESUMES = 'resumes';
const RESUME_TEMPLATE = 'r-template';
const DASHBOARD = 'dashboard';
const RESUME = 'resumes';
const PREVIEW = 'preview';
const FULL_PREVIEW = 'full-preview';
const EDITOR = 'editor';
const PORTFOLIOS = 'portfolios';
const WEBSITES = 'websites';
const COVER_LETTERS = 'cover-letters';
const SECTIONS = 'sections';
const PERSONAL_INFORMATION = 'personal-information';
const HEADER_SECTION = 'header-section';
const SUMMARY = 'summary';
const WORK_EXPERIENCE = 'work-experience';
const SKILL = 'skill';
const SKILL_GROUP = 'skill-group';
const EDUCATION = 'education';
const CERTIFICATE = 'certificate';
const HOBBY = 'hobby';
const SOCIAL_LINK = 'social-link';
const LINKEDIN = 'linkedin';
const LANGUAGE = 'language';
const PROJECT = 'project';
const ACHIEVEMENT = 'achievement';
const RECIPIENT = 'recipient';
const SUBJECT = 'subject';
const GREETING_PARAGRAPH = 'greeting-paragraph';
const BODY = 'body';
const CLOSING_MESSAGE = 'closing-message';
const FORMAL_SALUTATION = 'formal-salutation';
const APPLICATION_DETAILS = 'application-details';
const SHOWCASE = 'showcase';
const PUBLIC_RESUME = 'r';
const PUBLIC_WEBSITE = 'w';
const PUBLIC_COVER_LETTER = 'cl';
const TEMPLATE = 'template';
const DEFAULT = 'default';
const MODE = 'mode';
const PAPER = 'paper';
const WEBSITE = 'website';
const THEME = 'theme';
const DARK = 'dark';
const LIGHT = 'light';
const SHARE = 'share';
const CONNECT = 'connect';
const UNIVERSITY_CODE = 'university-code';
const FILTER_CRITERIA = 'filter-criteria';
const REFERENCE = 'reference';
const JOB_PROFILE = 'job-profile';
const OPEN_TO_WORK = 'open-to-work';
const MATCHED_CANDIDATES = 'matched-candidates';
const UP = 'up';
const DOWN = 'down';
const RESEND_CONFIRMATION_EMAIL = 'resend-confirmation-email';
//employer
const E = 'e';
const EMPLOYERS = 'employers';
const SETTINGS = 'settings';
const ANALYTICS = 'analytics';
const JOB_POSTS = 'job-posts';
const ALL_APPLICANT = 'all-applicants';
const ALL_USERS = 'all-users';
const APPLICANTS = 'applicants';
const APPLICATIONS = 'applications';
const APPLICATION_HISTORY = 'application-history';
const SHORTLISTED = 'shortlisted';
const SHORTLIST = 'shortlist';
const ALL = 'all';
const UNREAD = 'unread';
const APPLIED = 'applied';
const MY_APPLICATION = 'my-application';
const SAVED = 'saved';
const HIRED = 'hired';
const REJECTED = 'rejected';
const APPLICATION_FORM_DETAILS = 'application-form-details';
const APPLICANTS_RESUME = 'resume';
const EVALUATIONS = 'evaluations';
const DOCUMENTS = 'documents';
const TIMELINE = 'timeline';
const PROFILE = 'profile';
const EVALUATION_ID = 'evaluationId';
const COMMENTS = 'comments';
const ALL_EVALUATIONS = 'all-evaluations';
const YOUR_EVALUATION = 'your-evaluation';
const NEW = 'new';
const EDIT = 'edit';
const DELETE = 'delete';
const APPEND = 'append';
const OPEN_LINK = 'openLink';
const JOB_DETAILS = 'job-details';
const APPLICATION_FORMS = 'application-forms';
const HIRING_TEAMS = 'hiring-teams';
const JOB_POST_HAS_PLACEMENT_DRIVE = 'job-post-has-placement-drive';
const OPEN_POSITION = 'open-position';
const POSTS_DRAFTED = 'posts-drafted';
const CLOSED_POSITION = 'closed-position';
const CONFIGURATIONS = 'configurations';
const APPLICATION_FIELDS = 'application-field';
const CONFIGURATION_DOCUMENTS = 'configuration-documents';
const HIRING_ROUNDS = 'hiring-rounds';
const SCORE_CARDS = 'score-cards';
const CAREER_SITES = 'career-sites';
const COMPANY_DETAILS = 'company-details';
const CAREER_PAGES = 'career-pages';
const CONTACT_PERSONS = 'contact-persons';
const COMPANY = 'company';
const JOBS = 'jobs';
const EXTERNAL_JOBS = 'external-jobs';
const RECOMMENDED_JOBS = 'recommended-jobs';
const CONNECTED_EMPLOYERS = 'connected-employers';
const APPLY = 'apply';
const ABOUT_US = 'about-us';
const BASIC_INFORMATION = 'basic-information';
const UPLOAD_DOCUMENT = 'upload-document';
const UPLOAD = 'upload';
const APPLICATION_FIELD = 'application-field';
const REVIEW = 'review';
const EMPLOYER_ACCOUNTS = 'employer-accounts';
const MEMBERS = 'members';
const SUBSCRIPTIONS = 'subscriptions';
const NEED_HELP = 'need-help';
const TALENLIO_NETWORK = 'talenlio-network';
const CONNECTED = 'connected';
const INVITATION_RECEIVED = 'invitation-received';
const INVITATION_SENT = 'invitation-sent';
const NOTES = 'notes';
const COMMUNICATIONS = 'communications';
// university
const UNIVERSITY = 'u';
const ACCOUNT_ACTIVATION = 'account-activation';
const ACCOUNT_CREATION = 'account-creation';
const BATCHES = 'batches';
const PLACEMENTS = 'placements';
const DRIVES = 'drives';
const DRIVE = 'd';
const PLACEMENT_DRIVE = 'placement-drive';
const EXCLUSIVE_JOBS = 'exclusive-jobs';
const REPORTS = 'report';
const UNIVERSITY_ACCOUNTS = 'university-accounts';
const CANDIDATE_FIELDS = 'candidate-fields';
const CANDIDATE_FIELD_ANSWER = 'candidate-field-answer';
const BATCH_FIELD_ANSWER = 'batch-field-answer';
const SUPERVISORS = 'supervisors';
const PUBLISHED = 'published';
const UNPUBLISHED = 'unpublished';
const DRAFTED = 'drafted';
const IN_INTERVIEW_PROCESS = 'in-interview-process';
const JOB_OFFERED = 'job-offered';
const BRANDING_DETAILS = 'branding-details';
const NOTIFICATION_EVENTS = 'notification-events';
const TAGS = 'tags';
const FORMS = 'forms';
const FORM = 'f';
const BATCH_FORMS = 'batch-forms';
const ENROLLMENT_WEBSITE = 'enrollment-website';
const SECTION_NEW = 'section-new';
const SECTION_EDIT = 'section-edit';
const URL_HANDLE = 'url-handle';
const LIVE_PREVIEW = 'live-preview';
const TOKEN = 'token';
const LOADING = 'loading';
const PUBLIC = 'public';
const REDIRECT_URL = 'redirectUrl';
const USERS = 'users';
const SUBSCRIPTION_UNTIL = 'v';
const SHORTLISTED_CANDIDATE = 'shortlisted-candidate';
const BATCH_STATUS = 'batchStatus';

//IDs
const ENROLMENT_WEBSITE_ID = 'enrolmentWebsiteId';
const FORM_ID = 'formId';
const RESUME_ID = 'resumeId';
const ENHANCE_RESUME_ID = 'enhanceResumeId';
const RESUME_TEMPLATE_ID = 'rTemplateId';
const WEBSITE_ID = 'websiteId';
const COVER_LETTER_ID = 'coverLetterId';
const CANDIDATE_ID = 'candidateId';
const CANDIDATE_HAS_BATCH_ID = 'candidateHasBatchId';
const CANDIDATE_IDS = 'candidateIds';
const BATCH_IDS = 'batchIds';
const EMPLOYER_ID = 'employerId';
const PLACEMENT_DRIVE_ID = 'placementDriveId';
const UNIVERSITY_ID = 'universityId';
const UNIVERSITY_MEMBER_ID = 'universityMemberId';
const USER_ID = 'userId';
const BATCH_ID = 'batchId';
const JOB_POST_ID = 'jobPostId';
const JOBS_ID = 'jobsId';
const HIRING_ROUND_ID = 'hiringRoundId';
const SCORE_CARD_ID = 'scorecardId';
const CAREER_SITE_ID = 'careerSiteId';
const COMPANY_ID = 'companyId';
const CAREER_PAGE_ID = 'careerPageId';
const CONTACT_PERSON_ID = 'contactPersonId';
const EMPLOYER_MEMBER_ID = 'employerMemberId';
const JOB_POST_HAD_JOB_DETAIL_ID = 'jobPostHasJobDetailId';
const APPLICANT_ID = 'applicantId';
const COMPANY_URL = 'companyUrl';
const SECTION_ID = 'sectionId';
const SHORTLIST_ID = 'shortlistId';
const APPLICANT_HAS_HIRING_ROUND_ID = 'applicantHasHiringRoundId';

// SUPPORT
const SUPPORT = 'support';

// NOTIFICATION
const NOTIFICATIONS = 'notifications';
const NOT_FOUND = 'not-found';
const CALLBACK = 'callback';

export class ROUTE {
    public static WILDCARD = WILDCARD;
    public static PARENT_ROUTE = PARENT_ROUTE;
    public static SLASH = SLASH;
    public static COLON = COLON;
    public static SECTION_NEW = SECTION_NEW;
    public static SECTION_EDIT = SECTION_EDIT;
    public static TOKEN = TOKEN;
    public static LOADING = LOADING;
    public static PUBLIC = PUBLIC;
    //auth-layout
    public static LOGIN = LOGIN;
    public static SIGNUP = SIGNUP;
    public static SIGNUP_UPLOAD = SIGNUP_UPLOAD;
    public static SIGNUP_PASSWORD = SIGNUP_PASSWORD;
    public static REGISTER = REGISTER;
    public static FORGOT_PASSWORD = FORGOT_PASSWORD;
    public static PASSWORD = PASSWORD;
    public static CONFIRM_PASSWORD = CONFIRM_PASSWORD;
    public static FORGOT_PASSWORD_SUCCESS = FORGOT_PASSWORD_SUCCESS;
    public static RESET_PASSWORD = RESET_PASSWORD;
    public static RESET_PASSWORD_SUCCESS = RESET_PASSWORD_SUCCESS;
    public static INFORMATION = INFORMATION;
    public static CREDENTIALS = CREDENTIALS;
    public static SETUP_PROFILE = SETUP_PROFILE;
    public static SUBDOMAIN = SUBDOMAIN;
    public static PORTFOLIO_STATE = PORTFOLIO_STATE;
    public static BACK_URL = BACK_URL;
    public static MOBILE_TOKEN = MOBILE_TOKEN;
    public static NAVIGATE_BACK_URL = NAVIGATE_BACK_URL;
    public static REDIRECT_URL = REDIRECT_URL;

    //authentication
    public static AUTH = AUTH;
    public static AUTHORIZATION = AUTHORIZATION;
    public static JOIN_WAITLIST = JOIN_WAITLIST;
    public static UNIVERSITY_WAITLIST = UNIVERSITY_WAITLIST;
    public static EMPLOYER_WAITLIST = EMPLOYER_WAITLIST;
    public static SUCCESS = SUCCESS;
    public static EMAIL = EMAIL;
    public static FULL_NAME = FULL_NAME;
    public static WAITLIST = WAITLIST;
    public static WAITLIST_ID = WAITLIST_ID;
    public static EMPLOYER_ACCOUNT_ACTIVATION = EMPLOYER_ACCOUNT_ACTIVATION;
    public static CONTACT_PERSON = CONTACT_PERSON;
    public static CREATE = CREATE;
    public static JOB_PROFILE_ID = JOB_PROFILE_ID;
    public static PREVIEW = PREVIEW;
    public static USER_TYPE = USER_TYPE;
    public static GET_STARTED = GET_STARTED;
    public static VERIFY_EMAIL = VERIFY_EMAIL;
    public static FORM_SUCCESS = FORM_SUCCESS;
    public static QUESTIONNAIRE = QUESTIONNAIRE;

    //candidate
    public static CANDIDATE = C;
    public static R = R;
    public static INVITE = INVITE;
    public static SAVE = SAVE;
    public static CANDIDATES = CANDIDATES;
    public static ACTIVE_CANDIDATES = ACTIVE_CANDIDATES;
    public static INVITED_CANDIDATES = INVITED_CANDIDATES;
    public static HIRE_RESUME_EXPERT = HIRE_RESUME_EXPERT;
    public static DASHBOARD = DASHBOARD;
    public static RESUMES = RESUMES;
    public static RESUME_TEMPLATE = RESUME_TEMPLATE;
    public static RESUME = RESUME;
    public static FULL_PREVIEW = FULL_PREVIEW;
    public static EDITOR = EDITOR;
    public static PORTFOLIOS = PORTFOLIOS;
    public static WEBSITES = WEBSITES;
    public static COVER_LETTERS = COVER_LETTERS;
    public static SECTIONS = SECTIONS;
    public static PERSONAL_INFORMATION = PERSONAL_INFORMATION;
    public static HEADER_SECTION = HEADER_SECTION;
    public static SUMMARY = SUMMARY;
    public static WORK_EXPERIENCE = WORK_EXPERIENCE;
    public static SKILL = SKILL;
    public static SKILL_GROUP = SKILL_GROUP;
    public static EDUCATION = EDUCATION;
    public static CERTIFICATE = CERTIFICATE;
    public static HOBBY = HOBBY;
    public static SOCIAL_LINK = SOCIAL_LINK;
    public static LINKEDIN = LINKEDIN;
    public static LANGUAGE = LANGUAGE;
    public static PROJECT = PROJECT;
    public static ACHIEVEMENT = ACHIEVEMENT;
    public static RECIPIENT = RECIPIENT;
    public static SUBJECT = SUBJECT;
    public static GREETING_PARAGRAPH = GREETING_PARAGRAPH;
    public static BODY = BODY;
    public static CLOSING_MESSAGE = CLOSING_MESSAGE;
    public static FORMAL_SALUTATION = FORMAL_SALUTATION;
    public static APPLICATION_DETAILS = APPLICATION_DETAILS;
    public static FILTER_CRITERIA = FILTER_CRITERIA;
    public static REFERENCE = REFERENCE;
    public static JOB_PROFILE = JOB_PROFILE;
    public static OPEN_TO_WORK = OPEN_TO_WORK;
    public static MATCHED_CANDIDATES = MATCHED_CANDIDATES;
    public static UP = UP;
    public static DOWN = DOWN;
    public static RESEND_CONFIRMATION_EMAIL = RESEND_CONFIRMATION_EMAIL;
    //resume-share
    public static SHOWCASE = SHOWCASE;
    public static PUBLIC_RESUME = PUBLIC_RESUME;
    public static PUBLIC_WEBSITE = PUBLIC_WEBSITE;
    public static PUBLIC_COVER_LETTER = PUBLIC_COVER_LETTER;
    public static SHARE = SHARE;
    public static CONNECT = CONNECT;
    public static UNIVERSITY_CODE = UNIVERSITY_CODE;
    //resume-template
    public static TEMPLATE = TEMPLATE;
    public static DEFAULT = DEFAULT;
    //resume-mode
    public static MODE = MODE;
    public static PAPER = PAPER;
    public static WEBSITE = WEBSITE;
    //resume-theme
    public static THEME = THEME;
    public static LIGHT = LIGHT;
    public static DARK = DARK;
    //employer
    public static E = E;
    public static EMPLOYERS = EMPLOYERS;
    //employers-job-posts
    public static JOB_POSTS = JOB_POSTS;
    public static NEW = NEW;
    public static EDIT = EDIT;
    public static DELETE = DELETE;
    public static APPEND = APPEND;
    public static OPEN_LINK = OPEN_LINK;
    public static JOB_DETAILS = JOB_DETAILS;
    public static APPLICATION_FORMS = APPLICATION_FORMS;
    public static HIRING_TEAMS = HIRING_TEAMS;
    public static JOB_POST_HAS_PLACEMENT_DRIVE = JOB_POST_HAS_PLACEMENT_DRIVE;
    //employers-job-posts-applicants
    public static APPLICANTS = APPLICANTS;
    public static ALL_APPLICANT = ALL_APPLICANT;
    public static ALL_USERS = ALL_USERS;
    public static APPLICANT_ID = APPLICANT_ID;
    public static APPLICATIONS = APPLICATIONS;
    public static APPLICATION_HISTORY = APPLICATION_HISTORY;
    public static SHORTLISTED = SHORTLISTED;
    public static SHORTLIST = SHORTLIST;
    public static ALL = ALL;
    public static UNREAD = UNREAD;
    public static APPLIED = APPLIED;
    public static MY_APPLICATION = MY_APPLICATION;
    public static RECOMMENDED_JOBS = RECOMMENDED_JOBS;
    public static SAVED = SAVED;
    public static HIRED = HIRED;
    public static REJECTED = REJECTED;
    public static APPLICATION_FORM_DETAILS = APPLICATION_FORM_DETAILS;
    public static APPLICANTS_RESUME = APPLICANTS_RESUME;
    public static EVALUATIONS = EVALUATIONS;
    public static DOCUMENTS = DOCUMENTS;
    public static TIMELINE = TIMELINE;
    public static PROFILE = PROFILE;
    public static EVALUATION_ID = EVALUATION_ID;
    public static COMMENTS = COMMENTS;
    public static YOUR_EVALUATION = YOUR_EVALUATION;
    public static ALL_EVALUATIONS = ALL_EVALUATIONS;
    public static OPEN_POSITION = OPEN_POSITION;
    public static POSTS_DRAFTED = POSTS_DRAFTED;
    public static CLOSED_POSITION = CLOSED_POSITION;
    //employers-settings
    public static SETTINGS = SETTINGS;

    //employer-analytics
    public static ANALYTICS = ANALYTICS;

    //configuration
    public static CONFIGURATIONS = CONFIGURATIONS;
    public static CONFIGURATION_DOCUMENT = CONFIGURATION_DOCUMENTS;
    public static APPLICATION_FIELDS = APPLICATION_FIELDS;
    public static HIRING_ROUNDS = HIRING_ROUNDS;
    public static SCORE_CARDS = SCORE_CARDS;
    //career site
    public static CAREER_SITES = CAREER_SITES;
    public static COMPANY_DETAILS = COMPANY_DETAILS;
    public static CAREER_PAGES = CAREER_PAGES;
    public static CONTACT_PERSONS = CONTACT_PERSONS;
    public static COMPANY = COMPANY;
    public static JOBS = JOBS;
    public static EXTERNAL_JOBS = EXTERNAL_JOBS;
    public static CONNECTED_EMPLOYERS = CONNECTED_EMPLOYERS;
    public static APPLY = APPLY;
    public static ABOUT_US = ABOUT_US;
    public static BASIC_INFORMATION = BASIC_INFORMATION;
    public static UPLOAD_DOCUMENT = UPLOAD_DOCUMENT;
    public static UPLOAD = UPLOAD;
    public static APPLICATION_FIELD = APPLICATION_FIELD;
    public static REVIEW = REVIEW;
    //employer account
    public static EMPLOYER_ACCOUNTS = EMPLOYER_ACCOUNTS;
    public static MEMBERS = MEMBERS;
    public static SUBSCRIPTIONS = SUBSCRIPTIONS;
    public static NEED_HELP = NEED_HELP;
    public static TALENLIO_NETWORK = TALENLIO_NETWORK;
    public static CONNECTED = CONNECTED;
    public static INVITATION_RECEIVED = INVITATION_RECEIVED;
    public static INVITATION_SENT = INVITATION_SENT;
    public static COMMUNICATIONS = COMMUNICATIONS;
    public static NOTES = NOTES;
    //university
    public static UNIVERSITY = UNIVERSITY;
    public static ACCOUNT_ACTIVATION = ACCOUNT_ACTIVATION;
    public static ACCOUNT_CREATION = ACCOUNT_CREATION;
    public static BATCHES = BATCHES;
    public static PLACEMENTS = PLACEMENTS;
    public static DRIVES = DRIVES;
    public static DRIVE = DRIVE;
    public static PLACEMENT_DRIVE = PLACEMENT_DRIVE;
    public static EXCLUSIVE_JOBS = EXCLUSIVE_JOBS;
    public static REPORTS = REPORTS;
    public static UNIVERSITY_ACCOUNTS = UNIVERSITY_ACCOUNTS;
    public static CANDIDATE_FIELDS = CANDIDATE_FIELDS;
    public static CANDIDATE_FIELD_ANSWER = CANDIDATE_FIELD_ANSWER;
    public static SUPERVISORS = SUPERVISORS;
    public static PUBLISHED = PUBLISHED;
    public static UNPUBLISHED = UNPUBLISHED;
    public static DRAFTED = DRAFTED;
    public static IN_INTERVIEW_PROCESS = IN_INTERVIEW_PROCESS;
    public static JOB_OFFERED = JOB_OFFERED;
    public static BRANDING_DETAILS = BRANDING_DETAILS;
    public static NOTIFICATION_EVENTS = NOTIFICATION_EVENTS;
    public static TAGS = TAGS;
    public static FORMS = FORMS;
    public static BATCH_FORMS = BATCH_FORMS;
    public static ENROLLMENT_WEBSITE = ENROLLMENT_WEBSITE;
    public static FORM = FORM;
    public static ENROLMENT_WEBSITE_ID = ENROLMENT_WEBSITE_ID;
    public static SHORTLISTED_CANDIDATE = SHORTLISTED_CANDIDATE;
    public static BATCH_STATUS = BATCH_STATUS;

    //IDs
    public static CANDIDATE_ID = CANDIDATE_ID;
    public static CANDIDATE_HAS_BATCH_ID = CANDIDATE_HAS_BATCH_ID;
    public static CANDIDATE_IDS = CANDIDATE_IDS;
    public static BATCH_IDS = BATCH_IDS;
    public static FORM_ID = FORM_ID;
    public static RESUME_ID = RESUME_ID;
    public static ENHANCE_RESUME_ID = ENHANCE_RESUME_ID;
    public static RESUME_TEMPLATE_ID = RESUME_TEMPLATE_ID;
    public static WEBSITE_ID = WEBSITE_ID;
    public static COVER_LETTER_ID = COVER_LETTER_ID;
    public static EMPLOYER_ID = EMPLOYER_ID;
    public static USERS = USERS;
    public static USER_ID = USER_ID;
    public static JOB_POST_ID = JOB_POST_ID;
    public static JOBS_ID = JOBS_ID;
    public static HIRING_ROUND_ID = HIRING_ROUND_ID;
    public static SCORE_CARD_ID = SCORE_CARD_ID;
    public static CAREER_SITE_ID = CAREER_SITE_ID;
    public static COMPANY_ID = COMPANY_ID;
    public static CAREER_PAGE_ID = CAREER_PAGE_ID;
    public static CONTACT_PERSON_ID = CONTACT_PERSON_ID;
    public static EMPLOYER_MEMBER_ID = EMPLOYER_MEMBER_ID;
    public static JOB_POST_HAD_JOB_DETAIL_ID = JOB_POST_HAD_JOB_DETAIL_ID;
    public static COMPANY_URL = COMPANY_URL;
    public static SECTION_ID = SECTION_ID;
    public static SHORTLIST_ID = SHORTLIST_ID;
    public static APPLICANT_HAS_HIRING_ROUND_ID = APPLICANT_HAS_HIRING_ROUND_ID;
    // url Handle
    public static URL_HANDLE = URL_HANDLE;
    public static LIVE_PREVIEW = LIVE_PREVIEW;
    //university
    public static UNIVERSITY_ID = UNIVERSITY_ID;
    public static UNIVERSITY_MEMBER_ID = UNIVERSITY_MEMBER_ID;
    public static BATCH_ID = BATCH_ID;
    public static SUBSCRIPTION_UNTIL = SUBSCRIPTION_UNTIL;
    public static PLACEMENT_DRIVE_ID = PLACEMENT_DRIVE_ID;
    public static BATCH_FIELD_ANSWER = BATCH_FIELD_ANSWER;

    // SUPPORT
    public static SUPPORT = SUPPORT;

    // NOTIFICATIONS
    public static NOTIFICATIONS = NOTIFICATIONS;
    public static NOT_FOUND = NOT_FOUND;
    public static CALLBACK: string = CALLBACK;

}

export class QUERY_PARAM {
    //jobpost-datasheet-tabs
    public static JOB_DETAILS = 'job-details';
    public static APPLICATION_FIELDS = 'application-field';
    public static HIRING_STAGES = 'hiring-stages';
    public static HIRING_ROUNDS = 'hiring-rounds';
    public static HIRING_TEAMS = 'hiring-teams';
    //hiring-round-datasheet-tabs
    public static HIRING_ROUND_DETAILS = 'hiring-round-details';
    public static HIRING_ROUND_SCORECARD = 'hiring-round-scorecards';
    //members-datasheet-tabs
    public static MEMBERS = 'members';
    public static MEMBER_ID = 'memberId';
    //source
    public static EDITOR = 'editor';
    public static FULL_PREVIEW = 'full-preview';
    // view mode
    public static DESKTOP = 'desktop';
    public static MOBILE = 'mobile';
    // active
    public static ACTIVE = 'active';
    // utm
    public static UTM_SOURCE = 'utm_source';
    public static UTM_MEDIUM = 'utm_medium';
    public static UTM_CAMPAIGN = 'utm_campaign';
}
