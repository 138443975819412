import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClientWrapperService} from "../../../common/service/http-client-wrapper.service";
import {SearchInDto, SearchOutDto} from "../../../common/model/search.model";
import {LocationOutDto} from "../../../common/model/location/location.model";
import {DepartmentOutDto} from "../../../../common/service/common-department.service";
import {JobTypeOutDto} from "../../../../common/service/common-job-type.service";
import {JobTitleOutDto} from "../../../common/model/job-title/job-title.model";
import {JobLevelOutDto} from "../../../../common/service/common-job-level.service";
import {CURRENCY_CODE} from "../../../common/util/currency.util";
import {AttachmentOutDto} from "../../../common/component/upload/common/service/document.service";
import {DegreeOutDto} from "../../../common/service/common-degree.service";
import {ApplicantMinOutDto} from "./applicant.service";
import {APPLICANT_STATUS_TYPE} from "./candidate-has-job-application.service";
import {EMPLOYER_MEMBER_TYPE, JOB_POST_STATUS, PLACEMENT_DRIVE_STATUS, ROUND_TYPE} from "../util/job-post-status.enum";
import {JobPostOutDto} from "./job-post.service";

@Injectable({
    providedIn: 'root'
})
export class CandidateHasJobService {

    private candidateUrl = '/candidates';
    private jobPostUrl = '/jobPosts';
    private driveJobsUrl = '/driveJobs';
    private searchUrl = '/search';

    constructor(private httpClientWrapperService: HttpClientWrapperService) {
    }

    search(candidateId: string, candidateHasJobPostSearchInDto: CandidateHasJobPostSearchInDto):
        Observable<SearchOutDto<CandidateHasJobPostOutDto>> {
        return this.httpClientWrapperService.post(`${this.getSearchUrl(candidateId)}`, candidateHasJobPostSearchInDto);
    }

    searchPlacementDriveJobs(candidateId: string, candidateHasJobPostSearchInDto: CandidateHasJobPostSearchInDto):
        Observable<SearchOutDto<CandidateHasJobPostOutDto>> {
        return this.httpClientWrapperService.post(`${this.getDriveSearchUrl(candidateId)}`, candidateHasJobPostSearchInDto);
    }

    private getSearchUrl(candidateId: string): string {
        return `${this.candidateUrl}/${candidateId}${this.jobPostUrl}${this.searchUrl}`;
    }

    private getDriveSearchUrl(candidateId: string): string {
        return `${this.candidateUrl}/${candidateId}${this.driveJobsUrl}${this.searchUrl}`;
    }
}

export class CandidateHasJobPostOutDto {
    jobPostOutDto: JobPostOutDto;
    employerName: string;
    appliedAt: string;
    candidateHasJobPostId: string;
    employerUrlHandle: string;
    resumeIds: string[];
    applicantId: string;
    placementDriveMinOutDto: PlacementDriveMinOutDto;
    applicantMinOutDto: ApplicantMinOutDto;
    currentApplicantStatusOutDto: ApplicantStatusOutDto;
}

export class ApplicantStatusCommonDto {
    displayName: string;
    color?: string;
    applicantStatusType?: APPLICANT_STATUS_TYPE;
}

export class ApplicantStatusOutDto extends ApplicantStatusCommonDto {
    id: string;
}

export class PlacementDriveMinOutDto {
    placementDriveStatus: PLACEMENT_DRIVE_STATUS;
    name: string;
    fromDate: string;
    toDate: string;
    startTime: string;
    endTime: string;
    id: string;
    createdAt: string;
    lastModifiedAt: string;
}

export class HiringRoundMinOutDto {
    id: string;
    name: string;
    roundType: ROUND_TYPE;
    metadata: string;
    ctaDisplayText: string;
}

export class JobPostHasHiringRoundMinOutDto {
    id: string;
    rowIndex: number;
    hiringRoundMinOutDto: HiringRoundMinOutDto;
}

export class CandidateHasJobPostSearchInDto extends SearchInDto {
    jobPostIds?: string[];
    employerIds?: string[];
    myApplication?: boolean = false;
    relevantJob?: boolean;
    applicantStatusIds?: string[];
}

export class AssessmentCardOutDto {
    hiringRoundType: ROUND_TYPE;
    metadata: string;
    ctaDisplayText: string;
    companyName: string;
}

export class JobPostCommonDto {
    status?: JOB_POST_STATUS;
    applicationLink?: string;
    urlHandle?: string;
    responsibilities?: string;
    description?: string;
    requirements?: string;
    minSalary?: string;
    maxSalary?: string;
    currency?: CURRENCY_CODE;
    jobFeatured?: boolean;
    workType?: string;
    expectedResumeScore?: number;
    autoRejectDays?: string;
    publicJob?: boolean;
}

export class JobPostHasDegreeOutDto {
    id: string;
    degreeOutDto: DegreeOutDto;
}

export class HiringRound {
    name: string;
    roundType: ROUND_TYPE;
    candidatePreparationMaterial: string;
    employerPreparationMaterial: string;
    metadata: string;
    ctaDisplayText: string;
}

export class HiringRoundOutDto extends HiringRound {
    id: string;
    scorecardOutDtos: ScorecardOutDto[];
}

export class ScorecardHasAttribute {
    attributeName: string;
}

export class ScorecardHasAttributeOutDto extends ScorecardHasAttribute {
    id: string;
}

export class Scorecard {
    name: string;
}

export class ScorecardOutDto extends Scorecard {
    id: string;
    scorecardHasAttributeOutDtos: ScorecardHasAttributeOutDto[];
}

export class CompanyCommonDto {
    name?: string;
    websiteLink?: string;
    urlHandle?: string;
    address?: string;
    featured?: boolean;
}

export class EmployerHasMember {
    employerMemberType: EMPLOYER_MEMBER_TYPE;
}

export class CompanyMinOutDto extends CompanyCommonDto {
    id: string;
    logoOutDto: AttachmentOutDto;
    lastModifiedAt: string;
    createdAt: string;
}

export class JobPostMinOutDto extends JobPostCommonDto {
    id: string;
    externalJob: boolean;
    locationOutDto: LocationOutDto;
    departmentOutDto: DepartmentOutDto;
    jobTypeOutDto: JobTypeOutDto;
    jobTitleOutDto: JobTitleOutDto;
    jobLevelOutDto: JobLevelOutDto;
    companyOutDto: CompanyOutDto;
    createdAt: string;
    lastModifiedAt: string;
}

export class CompanyOutDto extends CompanyCommonDto {
    id: string;
    logoOutDto: AttachmentOutDto;
    // TODO : add later CareerSiteOutDto
    careerSiteOutDto: any;
    lastModifiedAt: string;
    createdAt: string;
}
