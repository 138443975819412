@switch (libType) {
    @case (BUTTON_TYPE.BASIC) {
        <button mat-button
                disableRipple="true"
                class="tal-basic-button lib-button-padding"
                [ngClass]="{
                    'bg-primary border-primary primary-button': libSubType === BUTTON_SUB_TYPE.PRIMARY && showBorder,
                    'bg-gradient text-white': libSubType === BUTTON_SUB_TYPE.GRADIENT && showBorder,
                    'text-primary bg-white border-primary-light primary-button-light secondary-button': libSubType ===
                    BUTTON_SUB_TYPE.SECONDARY && showBorder,
                    'text-white bg-danger border-danger danger-button': libSubType === BUTTON_SUB_TYPE.DANGER && showBorder,
                    'text-danger border-danger-light danger-button-secondary': libSubType === BUTTON_SUB_TYPE.DANGER_SECONDARY &&
                    showBorder,
                    'warning-button': libSubType === BUTTON_SUB_TYPE.WARNING && showBorder,
                    'success-button': libSubType === BUTTON_SUB_TYPE.SUCCESS && showBorder
                    }"
                [disabled]="disabled || spinner"
                (click)="onClick($event)">
            <ng-template [ngTemplateOutlet]="buttonRef"></ng-template>
        </button>
    }
    @case (BUTTON_TYPE.GRADIENT) {
        <button mat-button
                disableRipple="true"
                class="tal-gradient-button lib-button-padding"
                [ngClass]="{
            'tal-gradient-button-image text-white': libSubType === BUTTON_SUB_TYPE.GRADIENT && showBorder,
        }"
                [disabled]="disabled || spinner"
                (click)="onClick($event)">

            <ng-template [ngTemplateOutlet]="buttonRef"></ng-template>
        </button>
    }
    @case (BUTTON_TYPE.LARGE) {
        <button mat-button
                disableRipple="true"
                class="tal-large-button lib-button-padding"
                [ngClass]="{
                    'text-white bg-primary border-primary primary-button': libSubType === BUTTON_SUB_TYPE.PRIMARY,
                    'bg-gradient text-white': libSubType === BUTTON_SUB_TYPE.GRADIENT && showBorder,
                    'text-primary bg-white border-primary-light primary-button-light': libSubType ===
                    BUTTON_SUB_TYPE.SECONDARY,
                    'text-white bg-danger border-danger danger-button': libSubType === BUTTON_SUB_TYPE.DANGER,
                    'text-danger border-danger-light danger-button-secondary': libSubType === BUTTON_SUB_TYPE.DANGER_SECONDARY,
                    'warning-button': libSubType === BUTTON_SUB_TYPE.WARNING,
                    'success-button': libSubType === BUTTON_SUB_TYPE.SUCCESS
                    }"
                [disabled]="disabled || spinner"
                (click)="onClick($event)">
            <ng-template [ngTemplateOutlet]="buttonRef"></ng-template>
        </button>
    }
    @case (BUTTON_TYPE.ICON) {
        <button class="tal-icon-button lib-button-padding"
                [matTooltip]="tooltipText"
                [ngClass]="{
                    'text-white bg-primary border-primary primary-button': libSubType === BUTTON_SUB_TYPE.PRIMARY,
                    'text-primary bg-white border-primary-light primary-button-light': libSubType ===
                    BUTTON_SUB_TYPE.SECONDARY,
                    'bg-white border-primary-light primary-button-light': libSubType === BUTTON_SUB_TYPE.DEFAULT
                    }"
                [disabled]="disabled || spinner"
                (click)="onClick($event)">
            <ng-template [ngTemplateOutlet]="iconButtonRef"></ng-template>
        </button>
    }
    @case (BUTTON_TYPE.SMALL_ICON) {
        <button class="tal-icon-small-button lib-button-padding"
                [matTooltip]="tooltipText"
                [ngClass]="{
                    'text-white bg-primary border-primary primary-button': libSubType === BUTTON_SUB_TYPE.PRIMARY,
                    'text-primary bg-white border-primary-light primary-button-light': libSubType ===
                    BUTTON_SUB_TYPE.SECONDARY
                    }"
                [disabled]="disabled || spinner"
                (click)="onClick($event)">
            <ng-template [ngTemplateOutlet]="iconButtonRef"></ng-template>
        </button>
    }
    @case (BUTTON_TYPE.FAB) {
        <div class="bg-white rounded-full">
            <button mat-icon-button
                    type="button"
                    [ngClass]="{
                        'text-primary bg-primary-light': libSubType === BUTTON_SUB_TYPE.PRIMARY,
                        'text-primary bg-white-light': libSubType === BUTTON_SUB_TYPE.SECONDARY,
                        'text-warn bg-warning-light': libSubType === BUTTON_SUB_TYPE.WARNING,
                        'text-danger bg-danger-light': libSubType === BUTTON_SUB_TYPE.DANGER,
                        'text-success bg-success-light': libSubType === BUTTON_SUB_TYPE.SUCCESS
                    }"
                    [matTooltip]="tooltipText"
                    [disabled]="disable"
                    (click)="buttonClick.emit($event)">
                <mat-icon class="w-5 h-5 text-2xl"
                          [color]="libSubType === BUTTON_SUB_TYPE.PRIMARY ? 'primary' : null"
                          [fontIcon]="iconClass">
                    <!--  TODO: check [fontIcon]="iconClass"-->
                </mat-icon>
            </button>
        </div>
    }
}

<ng-template #buttonRef>
    <div class="flex flex-row justify-center items-center">
        @if (spinner) {
            <mat-icon class="mr-2">
                <mat-spinner diameter="20"></mat-spinner>
            </mat-icon>
        }
        @if (svgUrl) {
            <div class="w-6 h-6 mr-2">
                <img class="w-full" [src]="svgUrl" alt="">
            </div>
        }
        @if (!spinner && iconClass && iconPosition === BUTTON_ICON_POSITION.BEFORE) {
            <div class="mr-0.5 text-2xl" [ngClass]=iconClass></div>
        }
        <ng-content></ng-content>
        @if (iconClass && iconPosition === BUTTON_ICON_POSITION.AFTER) {
            <div class="ml-0.5 text-2xl" [ngClass]=iconClass></div>
        }
    </div>
</ng-template>

<ng-template #iconButtonRef>
    <div class="flex flex-row justify-center items-center">
        <div class="text-2xl" [ngClass]=iconClass></div>
    </div>
</ng-template>
